<template>
    <div class="friends bc-wrapper bc-horizontal">
        <div class="wrapper">
            <h1>Affiliate Program</h1>
            <p>
                Over the years, we have seen many of our clients refer their friends to our website.
                We are thankful for that and it helps us immensely! Our recently updated &laquo;Affiliate Program&raquo; is a way to partner with you and make it a "win-win"
                situation for both.
            </p>
            <div class="friends_info">
                <div class="section">
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Affiliate Status Bar
                        </h2>
                    </div>
                    <status-widget
                        :statistics="getterFriends"
                    />
                </div>
                <div class="section">
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            How you can earn with our platform
                        </h2>
                    </div>
                    <div class="video-section">
                        <ul>
                            <li>
                                <Check
                                    height="35px"
                                    width="35px"
                                />
                                <span>
                                    Identify the people that may be interested in our service
                                </span>
                            </li>
                            <li>
                                <Check
                                    height="35px"
                                    width="35px"
                                />
                                <span>
                                    Tell them about how easy it is to get help on our platform
                                </span>
                            </li>
                            <li>
                                <Check
                                    height="35px"
                                    width="35px"
                                />
                                <span>
                                    Share a sign-up link via WhatsApp or any other messenger
                                </span>
                            </li>
                            <li>
                                <Check
                                    height="35px"
                                    width="35px"
                                />
                                <span>
                                    Alternatively, add your friend's e-mail directly using our Sharing Widget and our system
                                    will send them a registration invite
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="section how-it-works">
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Video presentation
                        </h2>
                    </div>
                    <div class="how-it-works__block">
                        <div class="how-it-works__bullets">
                            <ul>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        Earn up to 25% on each order made by your referrals
                                    </span>
                                </li>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        Refer as many people as you want
                                    </span>
                                </li>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        Cash out your balance when you want
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="how-it-works__video">
                            <video
                                width="100%"
                                height="100%"
                                controls
                            >
                                <source
                                    :src="require('@/assets/video/Friends_Referral_Program_2.mp4')"
                                    type="video/mp4"
                                >
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>

                <div class="section">
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Our affiliate plans
                        </h2>
                    </div>
                    <div class="plans">
                        <AffiliatePlansRegular
                            :is-money-maker-plan="isMoneyMakerPlan"
                        />
                        <AffiliatePlansMoneyMaker
                            :is-money-maker-plan="isMoneyMakerPlan"
                        />
                    </div>
                </div>

                <div class="section">
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Sharing widget
                        </h2>
                    </div>
                    <invite-widget />
                </div>
                <AffiliateFaq>
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            frequently asked questions
                        </h2>
                    </div>
                </AffiliateFaq>
            </div>
        </div>
    </div>
</template>

<script>
import StatusWidget from '@/components/friends/StatusWidget.vue';
import InviteWidget from '@/components/friends/InviteWidget.vue';
import Check from 'mdi-vue/Check.vue'

import { mapGetters } from 'vuex'

import AffiliatePlansRegular from './components/AffiliatePlansRegular.vue';
import AffiliatePlansMoneyMaker from './components/AffiliatePlansMoneyMaker.vue';
import AffiliateFaq from './components/AffiliateFaq.vue';

export default {
    name: 'FriendsAbout',
    components: {
        StatusWidget,
        InviteWidget,
        Check,
        AffiliatePlansRegular,
        AffiliatePlansMoneyMaker,
        AffiliateFaq
    },
    data() {
        return {
            isMoneyMakerPlan: null
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterAffiliate',
            'getterFriends'
        ])
    },
    created() {
        this.isMoneyMakerPlan = this.getterAffiliate
    }
}
</script>

<style lang="scss">
    .friends {
        &_info {
            margin: 30px 0px;
            line-height: 1.4;
            text-align: left;
            color: #000;
            font-weight: 300;
            word-break: normal;
            &-wrapper {
                // padding: 15px;
                // box-shadow: 0 0 10px 2px rgb(0 0 0 / 10%);
                border-radius: 6px;
                margin-bottom: 25px;
            }
            &-title {
                font-weight: 700;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                margin: 10px 0;
                cursor: pointer;
            }
            &-title--active{
                color: $main-color;
            }
            &-chevron {
                cursor: pointer;
            }
            &-chevron--active{
                svg {
                    fill: $main-color;
                }
            }
        }
    }

    .section {
        margin: 80px 0px;
    }

    .white{
        color: #fff;
    }

    .plans {
        width: 100%;
        justify-content: space-evenly;
        display: flex;
        gap: 30px;

        @include media1200max {
            width: 100%;
        }
        @include media992max {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        @include media768max {
            width: 80%;
            margin: 0 auto;
        }
        @include media576max {
            width: 80%;
            margin: 0 auto;
        }
        &__title {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            background-color: rgba(gray, 0.1);
            padding: 20px 0;
            text-align: center;
        }
        &__base {
            width: 40%;
            border-radius: 10px;
            position: relative;
            @include media1200max {
                width: 50%;
            }
            @include media992max {
                width: 80%;
            }
            @include media768max {
                width: 100%;
            }
        }

        &__pro {
            width: 40%;
            border-radius: 10px;
            position: relative;
            @include media1200max {
                width: 50%;
            }
            @include media992max {
                width: 80%;
            }
            @include media768max {
                width: 100%;
            }
        }
        ul li{
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            margin: 15px 0;
        }
        &--active {
            border: 10px solid rgba($main-color, 1);
            // background-color: $main-color;
        }
        &--inactive {
            border: 2px solid grey;
        }
    }
    .title-percent {
        border: solid 2px;
        padding: 3px;
        border-radius: 5px;
        font-weight: bold;
    }
    .payment-options {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        &__item {
            font-size: 12px;
            font-weight: normal;
            border: solid 1px;
            padding: 2px 4px;
            white-space: nowrap;
            border-radius: 5px;
            opacity: 0.5;
        }
    }
    .card-body {
        padding: 25px;
    }
    .card-footer {
        display: flex;
        justify-content: center;
        background: #000;
        padding: 25px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border: 4px solid black;
    }
    .card-footer-currently-set {
        display: flex;
        justify-content: center;
        background: rgba($main-color, 1);
        padding: 25px;
        position: absolute;
        bottom: 0;
        left: 0;
        width:100%;
        @include media992max {
            position: inherit;
        }
    }

    .card-footer-currently-set--money-maker {
        display: flex;
        justify-content: center;
        background: rgba($main-color, 1);
        padding: 25px;
        bottom: 0;
        left: 0;
        width:100%;
        @include media992max {
            position: inherit;
        }
    }

    .how-it-works {

        margin: 0 !important;

        &__block {
            margin: 0 !important;
            display: flex;
            width: 100%;
            align-items: center;
            gap: 20px;
            @include media1200max {
                flex-direction: column;
                gap: 20px;
            }
        }

        &__bullets {
            align-items: center;
            margin: 15px 0;
            width: 75%;
            @include media1200max {
                width: 100%;
            }
            @include media768max {
                flex-direction: column;
                gap: 20px;
            }
        }

        &__bullets ul li {
            display: flex;
            align-items: baseline;
            margin-bottom: 10px;
            line-height: 30px;
            gap: 10px;
        }

        &__video {
            display: flex;
            justify-content: right;
            align-items: center;
            margin: 15px 0;
            @include media1200max {
                justify-content: center;
            }
        }
    }

    .video-section ul li{
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        line-height: 30px;
        gap: 10px;
    }

    .stripe {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 50px;
    }
    .line {
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
    }
    .center-text {
        text-align: center;
        font-size:18px !important;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #EAEBEF;
        padding: 5px 10px;
        z-index: 2;
    }
</style>
